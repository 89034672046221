import React from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Card } from "react-bootstrap";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import ContactForm from "../components/forms/contact";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const GlassLiftingPageTemplate = ({ title, titleSEO, imageContainer, logo, content }) => (
  <>
    <SEO title={titleSEO} />
    <Container className="d-flex flex-column justify-content-center align-items-center mb-5">
      <Row className="text-center my-5 pt-3">
        <h1 className="block-invert">{title}</h1>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <p className="pr-0 pr-md-5">
            <ReactMarkdown source={content} escapeHtml={false} linkTarget="_blank" />
          </p>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <PreviewCompatibleImage
            imageInfo={{
              image: imageContainer.image,
              alt: imageContainer.alt,
            }}
          />
          <PreviewCompatibleImage
            imageInfo={{
              image: logo.image,
              alt: logo.alt,
              style: { maxWidth: "300px" },
              className: "mx-auto mt-3",
            }}
          />
        </Col>
      </Row>
    </Container>
  </>
);

const GlassLiftingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <GlassLiftingPageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        imageContainer={frontmatter.imageContainer}
        logo={frontmatter.logo1}
        content={frontmatter.content}
      />
    </Layout>
  );
};

export default GlassLiftingPage;

export const pageQuery = graphql`
  query GlassLiftingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "glass-part-lifting-device-page" } }) {
      frontmatter {
        title
        titleSEO
        imageContainer {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        logo1 {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content
      }
    }
  }
`;
